<template>
  <div class="container bg">
    <div class="twoMenubox">
      <div class="item" :class="current == idx ? 'active' : ''" @click="menuHandle(i, idx)" v-for="(i, idx) in menu" :key="idx">
        <img style="width: 1.5rem;height: 1.65rem;" :src="current == idx ? i.icon : i.activeicon" alt="" />
        <div>{{ i.title }}</div>
      </div>
    </div>

<!--    <div class="list">-->
<!--      <list ref="liveList" v-if="current == 0" @add="add"></list>-->
<!--      <add v-if="current == 1" :img_id="imgId" @showList="add"></add>-->
<!--    </div>-->
<!--    TODO 右侧展示-->
    <div class="list">
      <div v-if="current==0">
        <div class="list_left">
          <div class="left_top">
            <div class="top_title">1：1真人克隆数字人</div>
            <div class="top_desc">提交2分钟录制画面，1：1还原真人神态，替代真人出镜，适用多种营销场景</div>
          </div>
          <div class="left_content">
            <div class="content_title">克隆模式：</div>
            <div class="label_checkradio">
              <el-radio v-model="radio" :label="2">高阶克隆</el-radio>
              <el-radio v-model="radio" :label="1">形象克隆</el-radio>
            </div>
            <div class="content_title" style="margin-top: 20px;margin-bottom: 20px">形象名称：</div>
            <el-input v-model="userName" placeholder="请输入形象名称" class="content_input"></el-input>
            <div class="div_upload">
                <div class="content_title" style="margin-top: 30px;">克隆素材：</div>
                <div class="upload_text">只能上传200MB以内的mp4文件</div>
                <div class="mt20">
                  <upload-video ref="uploads" :higher="info.calculations == 3 ? radio : info.calculations * 1"></upload-video>
                </div>
              </div>
            <div class="upload_tips"></div>
            <img src="~@/assets/img/icon_tips.png" style="width: 10px; height: 14px; position:relative;margin-left: -420px;margin-top: -112px">
            <div style="margin-top: -75px;font-size: 12px;margin-left: -10px">
              保证数字人形象定制克隆的效果，请阅读&nbsp;《视频拍摄规范》后录制视频。
            </div>
            <el-button type="primary" @click="debounceClick">提交审核</el-button>
          </div>
        </div>
        <div class="list_right">
          <div class="left_top">
            <div class="top_title">审核列表</div>
            <div class="top_desc">
              数字人克隆数量 ：
              <span class="desc_span">{{ info.calculations == 2 ? info.high_image_copy : info.image_copy }}</span> 次
              高阶克隆数量 :
              <span class="desc_span">{{ info.high_image_copy }}</span> 次</div>
          </div>
          <div class="right_content">
            <div class="right_content_title">
              <span style="margin-left: 14px;">形象克隆</span>
              <span style="margin-left: 64px">克隆素材</span>
              <span style="margin-left: 252px">创建时间</span>
              <span style="margin-left: 106px">状态</span>
              <span style="margin-left: 81px">备注</span>
            </div>
            <div v-for="(item, idx) in videoList" :key="idx" class="right_contents">
              <span style="margin-left: 14px; width: 100px;">{{item.name}}</span>
              <span style="margin-left: 24px; width: 290px; color: rgba(13, 225, 179, 1);">{{item.content}}</span>
              <span style="width: 180px;">{{item.create_date}}</span>
              <span class="content_status">{{item.status}}</span>
              <span style="width: 100px; margin-left: 100px">{{item.other}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="current==1">
        <div class="list_left">
          <div class="left_top">
            <div class="top_title">一次录音，创造无限可能</div>
            <div class="top_desc">提交2分钟录制音频，复刻真人音色、音律，克隆成功后可随意切换多国语言输出</div>
          </div>
          <div class="left_content">
            <div class="content_title" style="margin-top: 0px;margin-bottom: 20px">形象名称：</div>
            <el-input v-model="audioName" placeholder="请输入形象名称" class="content_input"></el-input>
            <div class="div_upload_audio">
                <div class="content_title" style="margin-top: 30px;">克隆素材：</div>
                <div class="upload_text">只能上传5MB以内的mp3文件</div>
                <div class="mt20">
                  <upload-audio ref="uploads"></upload-audio>
                </div>
              </div>
            <div class="upload_tips" style="margin-top: 32px;height: 162px"></div>
              <img src="~@/assets/img/icon_tips.png" style="width: 10px; height: 14px; position:relative;margin-left: -450px;margin-top: -320px">
              <div style="margin-top: -180px;font-size: 12px;margin-left: 30px; text-align: left; line-height: 22px;">
                温馨提示<br>
                1、提交的录音文件时长1-3分钟，建议为安静的环境。<br>
                2、录音状态自然、正常口语表达、停顿节奏自然，可以有一定情绪感情，但不要过分夸张。<br>
                3、发音清晰准确，无杂音，所有录音为同一人录制，不参杂其他人声<br>
                4、建议使用带话筒、性能较高的手机或收音设备录音。<br>
              </div>
            <el-button type="primary" @click="debounceClickAudio" >提交审核</el-button>
          </div>
        </div>
        <div class="list_right">
          <div class="left_top">
            <div class="top_title">审核列表</div>
            <div class="top_desc">
              声音克隆数量 ：
              <span class="desc_span">{{ info.calculations == 2 ? info.high_sound_copy : info.sound_copy }}</span> 次
            </div>
            <div class="right_content" style="position: relative; left: 0px; top: 53px">
              <div class="right_content_title">
                <span style="margin-left: 14px;">声音名称</span>
                <span style="margin-left: 64px">克隆素材</span>
                <span style="margin-left: 252px">创建时间</span>
                <span style="margin-left: 106px">状态</span>
                <span style="margin-left: 81px">备注</span>
              </div>
              <div v-for="(item, idx) in audioList" :key="idx" class="right_contents">
                <span style="margin-left: 14px; width: 100px;">{{item.name}}</span>
                <span style="margin-left: 24px; width: 290px; color: rgba(13, 225, 179, 1);">{{item.content}}</span>
                <span style="width: 180px;">{{item.create_date}}</span>
                <span class="content_status">{{item.status}}</span>
                <span style="width: 100px; margin-left: 100px">{{item.other}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UploadVideo from "@/components/UploadVideo.vue";
import { getUser } from "@/api/user.js";
import { sut_image_copy, set_image_copy, image_copy_list } from "@/api/video";
import UploadAudio from "@/components/UploadAudio.vue";
import { sut_sound_copy, edit_sound_copy, sound_copy_list } from "@/api/audio";
export default {
  components: {
    UploadVideo,
    UploadAudio
  },
  data() {
    return {
      audioName: "",
      vdSrc: "",
      userName: "",
      radio: 2,
      editId: null,
      timer: null,
      info: {},
      tableData: [],
      dialogVisible: false,
      innerVisible: false,
      imgId: "",
      current: 0,
      menu: [
        {
          icon: require("@/assets/img/secondMenu/peopleClone1.png"),
          activeicon: require("@/assets/img/secondMenu/peopleClone.png"),
          title: "克隆形象",
          pageName: "peopleClone",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/audioClone1.png"),
          activeicon: require("@/assets/img/secondMenu/audioClone.png"),
          title: "克隆声音",
          pageName: "audioClone",
          params: {},
        },
      ],
      videoList: [
        {
          'name':'个人形象',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
        {
          'name':'主播',
          'content':'素材文件1 素材文件2',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
        {
          'name':'个人形象',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
              {
          'name':'CES',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
              {
          'name':'TK',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
              {
          'name':'带货主播',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
      ],
      audioList: [
        {
          'name':'名称123',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
        {
          'name':'名称123',
          'content':'素材文件1 素材文件2',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
        {
          'name':'名称123',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
              {
          'name':'名称123',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
              {
          'name':'名称123',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
              {
          'name':'名称123ww',
          'content':'素材文件1',
          'create_date': '2023/12/18 19:36',
          'status': '审核完成',
          'other': '无'
        },
      ],
    };
  },
  watch: {
    radio() {
      this.$refs.uploads.clearFile();
    },
  },
  mounted() {
    // 渲染视频

    console.log(this.info);
    this.getInfo();
    this.userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : {};
    this.info = JSON.parse(localStorage.getItem("userInfo"));
    if (this.$route.params.imageId && this.$route.params.imageId > 0) {
      this.current = 1;
      this.imgId = this.$route.params.imageId;
    }
  },
  methods: {
    getInfo() {
      getUser().then((res1) => {
        if (res1.code == "200") {
          localStorage.setItem("userInfo", JSON.stringify(res1.data));
          this.info = res1.data;
        }
      });
    },
    getList() {
      image_copy_list().then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.dialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
      sound_copy_list().then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.dialogVisible = true;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    menuHandle(e, idx) {
      this.current = e ? idx : 0;
      const linkInfo = this.menu[this.current];
      console.log("[home] load page:", linkInfo);
    },
    showVideo(url) {
      this.vdSrc = url;
      this.innerVisible = true;
    },
    showAudio(url) {
      this.vdSrc = url;
      this.innerVisible = true;
    },
    goBack() {
      this.$router.push({ name: "home" });
    },
    editItem(e) {
      console.log("editItem", e);
      this.userName = e.name;
      this.audioName = e.sound_name;
      this.editId = e.id;
      this.dialogVisible = false;
    },
    debounceClick() {
      // 防抖代码
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.submit();
      }, 1200);
    },
    debounceClickAudio() {
      // 防抖代码
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        this.submit_audio();
      }, 1200);
    },
    submit() {
      console.log(111);
      console.log(this.$refs.uploads.fileList);
      if (!this.userName) {
        this.$message.error("请输入形象名称");
        return;
      }
      if (this.$refs.uploads.fileList.length < 1) {
        this.$message.error("请上传视频后提交克隆");
        return;
      }
      if ((this.info.calculations == 1 || this.radio == 1) && this.$refs.uploads.fileList.length < 3) {
        this.$message.error("请上传三段视频后提交克隆");
        return;
      }

      let params = {
        name: this.userName,
        copy_type: this.info.calculations == 3 ? this.radio : this.info.calculations,
        url1: this.$refs.uploads.fileList[0],
      };
      if (this.info.calculations == 1 || this.radio == 1) {
        params.url2 = this.$refs.uploads.fileList[1];
        params.url3 = this.$refs.uploads.fileList[2];
      }

      let result = null;
      if (this.editId != null) {
        params.image_id = this.editId;
        result = set_image_copy(params);
      } else {
        result = sut_image_copy(params);
      }
      result.then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          this.$message.success(res.msg);
          this.userName = "";
          this.editId = null
          this.$refs.uploads.clearFile();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    submit_audio() {
      console.log(this.$refs.uploads.fileList);

      if (!this.audioName) {
        this.$message.error("请输入声音名称");
        return;
      }
      if (this.$refs.uploads.fileList.length < 1) {
        this.$message.error("请上传音频后提交克隆");
        return;
      }

      const formData = new FormData();
      formData.append("file", this.$refs.uploads.fileList[0]);
      formData.append("token", localStorage.getItem("token"));
      formData.append("sound_name", this.audioName);

      let data = "";
      if (this.editId) {
        formData.append("sound_id", this.audioName);
        data = edit_sound_copy(formData);
      } else {
        data = sut_sound_copy(formData);
      }
      data.then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          this.$refs.uploads.clearFile();
          this.audioName = "";
          this.editId = null;
          this.$message.success(res.msg);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.box {
  // box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  // background: #fff;
  margin: 20px 0;
  margin-bottom: 0;
  height: 88vh;
  border-radius: 15px;
  overflow: hidden;

  .top {
    width: 100%;
    height: 140px;
    background: url(../../assets/img/clongBg.png) no-repeat;
    background-size: 100% 100%;
    padding: 40px 30px;
    border-radius: 15px;
    font-size: 28px;
    margin-top: 20px;
  }

  .info {
    text-align: left;
    margin: 40px 20px 25px;
    font-size: 16px;
    background: #f5f9ff;
    border-radius: 10px;
    padding: 10px 20px;
    width: 40vw;

    .label {
      width: 8vw;
    }
    .label_checkout{
      position: relative;
      display: flex;
    }

    .timeBox {
      height: 50px;
      line-height: 50px;

      .icon1 {
        font-size: 18px;
        color: #006dfe;
        margin-right: 12px;
      }
    }
  }

  .left {
    text-align: left;
    margin-left: 30px;
  }
}

.listIcon {
  font-size: 28px;
  padding: 3px 8px 2px;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
  // width: 50px;
  color: #53a8ff;
  text-align: center;
}
.twoMenubox {
  width: 210px;
  height: 57rem;
  background: #080114;
  // border-right: 1px solid #eef0f2;
  .item {
    font-size: 16px !important;
  }
}

.list{
  position: relative;
  width: 86%;
  height: 53rem;
  top: -55rem;
  left: 13.875rem;
  /*background: #471a1a;*/
}
.desc_span{
  color: rgba(13, 225, 179, 1);
}
.list_left{
  float: left;
  left: 20px;
  position: relative;
  width: 510px;
  height: 810px;
  opacity: 1;
  border-radius: 16px;
  background: rgba(32, 37, 50, 1);
  .left_top{
    position: relative;
    width: 510px;
    height: 80px;
    background: #5daf34;
    opacity: 1;
    padding-top: 15px;
    padding-bottom: 14px;
    padding-left: 30px;
    padding-right: 42px;
    border-radius: 16px 16px 0px 0px;
    background: rgba(45, 50, 64, 1);
    .top_title{
      position: relative;
      width: 250px;
      height: 27px;
      opacity: 1;
      /** 文本1 */
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 26.06px;
      color: rgba(13, 225, 179, 1);
      text-align: left;
      vertical-align: top;
    }
    .top_desc{
      position: relative;
      top: 0.4rem ;
      width: 463px;
      height: 19px;
      opacity: 0.6;
      /** 文本1 */
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 18.82px;
      color: rgba(255, 255, 255, 0.8);
      text-align: left;
      vertical-align: top;
    }
  }
  .left_content{
    color: white;
    position: relative;
    left: 30px;
    top: 31px;
    width: 450px;
    height: 29.3rem;
    opacity: 1;
    /*background: #1a3430;*/
  }
  ::v-deep{
    .el-button{
      width: 264px;
      height: 48px;
      margin-top: 50px;
      border: none;
      border-radius: 10px;
    }
    .el-button--primary{
      background: linear-gradient(90deg, rgba(54, 243, 170, 1) 0%, rgba(4, 221, 181, 1) 100%)!important;
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      color: rgba(2, 38, 30, 1);
    }
  }
  .upload_tips {
    text-align: left;
    position: relative;
    left: 0px;
    top: -15px;
    width: 450px;
    height: 47px;
    opacity: 0.1;
    border-radius: 10px;
    background: rgba(204, 204, 204, 1);
  }
  .content_title{
      position: relative;
      left: 0px;
      top: 10px;
      width: 4.5rem;
      height: 21px;
      opacity: 0.8;
      /** 文本1 */
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;
    }
  .upload_text{
      position: relative;
      left: 220px;
      top: -0.375rem;
      width: 14.5rem;
      height: 21px;
      opacity: 0.8;
      /** 文本1 */
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: rgba(255, 255, 255, 1);
      text-align: left;
      vertical-align: top;
  }
  .label_checkradio{
    position: relative;
    left: 0px;
    top: 1.9375rem;
    width: 190px;
    height: 31px;
    opacity: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  ::v-deep{
    .el-input__inner{
      position: relative;
      left: 0px;
      top: 10px;
      width: 450px;
      height: 50px;
      opacity: 1;
      border-radius: 10px;
      background: rgba(15, 19, 25, 1);
      border: 1px solid rgba(45, 50, 64, 1);
    }
  }
  .div_upload{
    width: 80rem;
    ::v-deep{
      .el-upload-dragger{
        position: relative;
        left: -1rem;
        top: -5px;
        width: 450px;
        height: 140px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(15, 19, 25, 1);
        border: 1px solid rgba(45, 50, 64, 1);
      }
      .avatar-uploader-icon{
        font-size: 1.75rem;
        color: #8c939d;
        width: 12.75rem;
        height: 1.5rem;
        line-height: 2.25rem;
        text-align: center;
      }
      .el-upload__text{
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .div_upload_audio{
    width: 80rem;
    ::v-deep{
      .el-upload-dragger{
        position: relative;
        left: -1rem;
        top: -5px;
        width: 450px;
        height: 140px;
        opacity: 1;
        border-radius: 10px;
        background: rgba(15, 19, 25, 1);
        border: 1px solid rgba(45, 50, 64, 1);
      }
      .el-upload__tip{
        display: none;
      }
      .avatar-uploader-icon{
        font-size: 1.75rem;
        color: #8c939d;
        width: 12.75rem;
        height: 1.5rem;
        line-height: 2.25rem;
        text-align: center;
        margin-top: 30px;
      }
      .el-upload__text{
        padding-top: 40px;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.list_right{
  float: left;
  position: relative;
  left: 60px;
  top: 0px;
  width: 930px;
  height: 810px;
  opacity: 1;
  border-radius: 16px;
  background: rgba(32, 37, 50, 1);
  .left_top{
    position: relative;
    width: 930px;
    height: 80px;
    background: #5daf34;
    opacity: 1;
    padding-top: 15px;
    padding-bottom: 14px;
    padding-left: 30px;
    padding-right: 42px;
    border-radius: 16px 16px 0px 0px;
    background: rgba(45, 50, 64, 1);
    .top_title{
      position: relative;
      width: 166px;
      height: 27px;
      opacity: 1;
      /** 文本1 */
      font-size: 18px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 26.06px;
      color: rgba(13, 225, 179, 1);
      text-align: left;
      vertical-align: top;
    }
    .top_desc{
      position: relative;
      top: 0.4rem ;
      width: 438px;
      height: 19px;
      opacity: 0.6;
      /** 文本1 */
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 18.82px;
      color: rgba(255, 255, 255, 0.8);
      text-align: left;
      vertical-align: top;
    }
  }
  .right_content{
    position: relative;
    left: 30px;
    top: 30px;
    width: 870px;
    height: 677px;
    opacity: 1;
    border-radius: 10px;
    background: rgba(28, 28, 36, 1);
    .right_content_title{
      position: relative;
      left: 10px;
      top: 13px;
      width: 850px;
      height: 32px;
      opacity: 1;
      border-radius: 5px;
      background: rgba(39, 39, 48, 1);
      span{
        position: relative;
        float: left;
        display: inline-block;
        width: 56px;
        height: 21px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;
        margin-top: 6px;
      }
    }
    .right_contents{
      position: relative;
      left: 10px;
      top: 10px;
      width: 850px;
      height: 40px;
      opacity: 1;
      background: rgba(28, 28, 36, 1);
      border-bottom: 1px solid rgba(32, 37, 50, 1);
      display: flex;
      span{
      position: relative;
      top: 16px;
      opacity: 1;
      /** 文本1 */
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(255, 255, 255, 0.8);
      text-align: left;
      vertical-align: top;
    }
      .content_status{
        padding-left: 4px;
        padding-top: 4px;
        width: 62px;
        height: 20px;
        opacity: 1;
        border-radius: 3px;
        background: rgba(25, 74, 249, 1);
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 12px;
        color: rgba(255, 255, 255, 1);
        text-align: center;
        vertical-align: top;
      }
    }
  }

}
// /deep/.el-upload {
//   border: 1px dashed #d9d9d9;
//   border-radius: 6px;
//   cursor: pointer;
//   position: relative;
//   overflow: hidden;
// }
/deep/.el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.vdBox {
  width: 315px;
  margin: 0 auto;
}

.navBox {
  background: #fff;
  padding: 10px 20px 10px;
}
</style>
